







































import {Component, Vue} from 'vue-property-decorator'
import {getOrders} from '@/api/courseOrder'

@Component({})
export default class UserBuyCourse extends Vue {

  private orderDto = new Array()

  private async activated() {
    await this.getOrderList()
  }

  private async getOrderList() {
    const result = await getOrders()
    this.orderDto = result
  }

  private handleClickCourse(item: any) {
    const start = `${item.openingDate} ${item.startDateTime}`
    const end = `${item.openingDate} ${item.endDateTime}`
    const openDateStart = new Date(start)
    const openDateEnd = new Date(end)
    const now = new Date()
    if (now < openDateStart) {
      this.$router.push({
        path: '/course-info',
        query: {
          courseId: item.id
        }
      })
    }
  }

  private handleClickState(state: number, item: any, orderId: string) {
    const start = `${item.openingDate} ${item.startDateTime}`
    const end = `${item.openingDate} ${item.endDateTime}`
    const openDateStart = new Date(start)
    const openDateEnd = new Date(end)
    const now = new Date()
    if (openDateStart > now && state === 0) {
      this.$router.push({
        path: '/user/course-order',
        query: {
          orderId
        }
      });
    }
  }

  private getCourseStatus(item: any) {
    const start = `${item.courseDto.openingDate} ${item.courseDto.startDateTime}`
    const end = `${item.courseDto.openingDate} ${item.courseDto.endDateTime}`
    const openDateStart = new Date(start)
    const openDateEnd = new Date(end)
    const now = new Date()
    let result = ''
    if (item.courseState === 1) {
      result = '已上课'
    } else if (item.orderState === -2) {
      result = '已退款'
    } else if (now > openDateEnd) {
      result = '已结束'
    } else if (openDateStart > now && item.orderState === 0) {
      result = '未付款'
    }

    return result
  }
}
